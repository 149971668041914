import React, {useEffect, useState } from 'react'
import { config, meta,  tabarray_posts_filter,  tabarray_posts_global } from '@/lib/config'
import { useUserAuth } from '@/lib/utils/hooks/useUserAuth'
import getMessage from '@/lib/utils/getMessage'
import { getCacheDate } from '@/lib/utils/getCacheDate'
import PostArray from '@/components/profile/post/post-array'
import getRecordArraySwr from '@/lib/utils/admin/server/record/getRecordArraySwr'
import LayoutPageBlank from '@/components/templateux/layout/layout-page-blank'
import NavPrimary from '@/components/templateux/nav/nav-primary'
import Footer from '@/components/templateux/footer'
import Tabs from '@/components/templateux/tabs'
import { useSession } from 'next-auth/client'
import { useRouter } from 'next/router'
import HeroCTA from '@/components/content/hero-cta'
import { LayoutPageBlankContentWrapper } from '@/components/templateux/layout/layout-page-blank-content-wrapper'

import HeaderBarHome from '@/components/templateux/header-bar/header-bar-home'
import SvgJsx from '@/components/templateux/svg/svg-jsx'

export async function getStaticProps(context) {

        const ssr_data_posts = 
        await getRecordArraySwr(
              "post" //table_name: string
            , "select_by_featured" //path_name: string
            , "0" //user_id: string
            , "0" //my_id:string
            , "1" //page: string
            , "RAND" //sort: string
            , config.itemdir //dir: string
            , '' //filter: string
        )

    
  return { 
      props: { 
         ssr_data_posts: ssr_data_posts
        //  ,ssr_data_hosts: ssr_data_hosts
         ,params: context?.params || null
        ,navtype: 'posts'
        ,navoption: 'featured'
        ,helptype: 'content'
        ,nodata: getMessage("noresults")
      } 
      ,revalidate: 300, // In seconds
    }
}

// }

function IndexPosts({ ssr_data_posts
                          // , ssr_data_hosts
                          , params
                          , navtype 
                          , navoption
                          , helptype
                          , nodata
                        }) {

  //VARS 
  var user_name = ''

  const page = 0
  const [sort, set_sort] = useState("broadcast")
  const cdir = config.itemdir
  const [dir, set_dir] = useState(cdir)  

   //search
 let [isOpen, set_isOpen] = useState(false)
 const [results,set_results] = useState(null)
 const [search,set_search] = useState('')
 
 const [cache, set_cache] = useState(getCacheDate())

  //PRELOAD SSR DATA

  const router = useRouter()
  
  const { userAuthData, isUserAuthLoading, isUserAuthError } = useUserAuth()
  const [view_mode,set_view_mode] = useState(navtype)
  const [is_searching, set_is_searching] = useState(false)
  const [search_text,set_search_text] = useState('')
  const [filter_text,set_filter_text] = useState('')


  const [change_cache,set_change_cache] = useState(null)
  const [display_view,set_display_view] = useState(navtype)
  const [accessby_view,set_accessby_view] = useState(navoption)
  const [swr_url,set_swr_url] = useState(`/api/private/post/select?option=${navoption}`)


  useEffect(()=>{
    if (accessby_view !== "tag") set_swr_url(`/api/private/post/select?option=${accessby_view}`)
  },[accessby_view])

  useEffect(()=>{set_display_view(navtype)},[navtype])



  //slow routing from next.js query parsing

  const submitSearch = (e) => {
    e.preventDefault()
    if (search_text?.length > 0) {
      set_accessby_view('tag')
      set_filter_text(search_text)
      set_swr_url(`/api/private/post/select?option=tag&q=tag:${search_text}`)
      
      router.push({
        pathname: display_view=="post" ? `/` : `/audio`,
        query: { search: search_text}
      }, 
      undefined, { shallow: true }
      )
    }
   
  }

  const clearSearch = (e) => {
    e.preventDefault()
    set_filter_text('')
    set_search_text('')
    set_is_searching(false);
    set_swr_url(`/api/private/post/select?option=${accessby_view !== "tag" ? accessby_view : "featured"}`)
    router.push({
      pathname: display_view=="post" ? `/` : `/audio`,
      query: {  }
    }, 
    undefined, { shallow: true }
    )
  }
  const [session, loading] = useSession()


  //PRELOAD SSR DATA

  //SEO TRICK - RECLAIM PREVIOUS SITEMAP INDEX
  if (JSON.stringify(router.query) == '{"sitemap.xml":""}') {
    if (typeof window !== 'undefined') window.location.href = `sitemap.xml`;
  }



  //slow routing from next.js query parsing

  useEffect(()=>{
    if (router?.query?.option == "featured") set_accessby_view("featured")
    if (router?.query?.option == "followed") set_accessby_view("followed")
    if (router?.query?.option == "all") set_accessby_view("all")
    if (router?.query?.search?.length > 0) {
      set_accessby_view("tag")
      set_filter_text(search_text)
      set_swr_url(`/api/private/post/select?option=tag&q=tag:${router?.query?.search}`)
    }
    set_search_text(router?.query?.search?.toString())
  },[router])




      //Admin Lookup
      const [isAdmin,set_isAdmin] = useState(userAuthData?.bol_admin?.toString()==1)
      

      const set_tab_header = (n,url) => {
        set_display_view(n);
        router.push(url, undefined, { shallow: true });
      }


  return (<>
      {/* MAIN CONTAINER */}
      <LayoutPageBlank
       title={"Home page"}
       url={process.env.NEXT_PUBLIC_SERVER_WEBSITE}
       image={meta.image}
      >

      <NavPrimary
            user_name={userAuthData?.user_name}
            userAuthData={userAuthData}
            isUserAuthLoading={isUserAuthLoading}
            isUserAuthError={isUserAuthError}
            navtype={navtype}
            />

          <LayoutPageBlankContentWrapper>

            <HeroCTA />

            <div className="sm:flex items-top content-start">
              {/* <div className="flex-0 sm:w-64 sm:min-w-64 shrink-0">
                        



              </div> */}
              <div className="flex-1">
                              <div className="z-50 sm:mt-2 mt-3 border-x rounded-t-md border-gray-700 sticky top-[47px]">
   

                              <HeaderBarHome
                                  headertitle={<div className="flex items-center content-center">
                                                  <div>
                                                    <Tabs
                                                      tabarray={tabarray_posts_filter('',false)}
                                                      clickval={display_view}
                                                      set_clickval={set_tab_header}
                                                      tab_style="selectboxhome"
                                                    />
                                                  </div>
                                                </div>}
                                  show_rss={false}
                                  rss_link={``}
                                  rss_text={'RSS'}
                                  action_button={<>
                                  </>}
                                  show_action_button={false}
                                /> 


                              <HeaderBarHome
                                        headertitle={<form
                                          onSubmit={(e)=> submitSearch(e)}
                                        >
                                        <div className="flex text-sm font-base w-full group cursor-pointer ">
                                          <input 
                                            className={`transition-all duration-500 border rounded-l-md  border-gray-700 group-hover:border-white  bg-gray-700 placeholder-gray-500 text-white px-2
                                              ${is_searching ? "flex-1 w-full " : "flex-0 w-20 xs:w-28 sm:w-48 lg:w-full"}
                                            `}
                                            onChange={(e)=>
                                                //set_is_searching(e.target.value.length > 0)
                                                set_search_text(e.target.value)
                                              }
                                            onFocus={(e)=>{set_is_searching(true)}}
                                            onBlur={(e)=>{set_is_searching(e.target.value.length > 0)}}
                                            defaultValue={search_text}
                                            placeholder={`Search`}
                                           
                                          />
                                          
                                          <div 
                                            className="border border-gray-700 group-hover:border-white p-2 rounded-r-md"
                                            onClick={(e)=> submitSearch(e)}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                                              submitSearch(e)
                                            }}}
                                          >
                                            <SvgJsx 
                                              type={'fill'}
                                              icon={'search-sm'}
                                              className={`h-5 w-5`}
                                              title={'Search'}
                                            />
                                          </div>
      
                                            {is_searching &&
                                            <div 
                                              className=" hover:text-white  p-2 "
                                              onClick={(e)=> clearSearch(e)}
                                              onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                                                
                                                clearSearch(e);
                                              }}}
                                              role="button"
                                              
                                              tabIndex={0}
                                            >
                                              <SvgJsx 
                                                type={'fill'}
                                                icon={'x-sm'}
                                                className={`h-5 w-5`}
                                                title={'Clear'}
                                              />
                                            </div>
                                            }
                                          </div>
                                        </form>
                                        
                                      }
                                        show_rss={false}
                                        rss_link={``}
                                        rss_text={'rss'}
                                        action_button={<>
                                        {!is_searching &&
                                        <Tabs
                                          tabarray={tabarray_posts_global(navtype)}
                                          clickval={accessby_view}
                                          set_clickval={set_accessby_view}
                                          tab_style="selectboxhome"
                                          userdata={userAuthData}
                                        />
                                        }
                                        </>}
                                      
                                        show_action_button={!is_searching}
                                        top={false}
                                      />

                                                                   

                               
                              </div>
                        
                              <div className="max-w-7xl mx-auto border-x border-gray-700">


                                
                                <PostArray 
                                      itemdata={ssr_data_posts}
                                      isLoading={ false}
                                      isError={ false }
                                      userdata={userAuthData}
                                      ownerdata={null}
                                      display_view={display_view}
                                      set_display_view={set_display_view}
                                      cache={cache}
                                      set_cache={set_cache}
                                      slugfilter={''}
                                      placeholderdata={[
                                      ]}
                                      nodata={nodata}
                                      navtype={navtype}
                                      helptype={helptype}
                                      isAdmin={isAdmin}
                                      slug={''}
                                      title={'All posts'}
                                      //swr_url={`/api/private/post/select?option=${accessby_view}${(accessby_view == "tag" && search_text?.length > 0) ? `&q=tag:${params?.tag}` : ""}`}
                                      swr_url={swr_url}
                                      />
                              </div>
              </div>
            </div>





        </LayoutPageBlankContentWrapper>
        <Footer />
      </LayoutPageBlank>
    </>

      )


  }
  

  export default IndexPosts