
import Link from 'next/link'
import {  useSession } from 'next-auth/client'
import  { useRouter } from 'next/router'
import {  footerlist, footerlist_sub } from '@/lib/config'

function Footer({
  target='' 
  ,rel='' 
}) {

  const router = useRouter()
  const strURL = encodeURI(router.asPath)
  const [session, loading ] = useSession()


  return (
    <>
  <footer className={` 
                      border mt-0 pb-10
                      border-gray-700
                      max-w-7xl mx-auto
                      bg-gray-900
                      rounded-b-md
                      shadow:md
  `}>
    <div className="mx-auto pt-6  px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav className=" flex flex-wrap justify-center " aria-label="Footer">

        {footerlist.map((e,index) => 
         <Link href={e.url} key={index} passHref>
         <a 
               target={`${e.internal == '1' ? target : '_blank'}`}
               rel={`${e.internal == '1' ? rel : 'noreferrer'}`}
               className={` 
               group
               border rounded-full
               ${e?.border == 1 
                              ? ` border-gray-700 hover:border-gray-200` 
                              : `border-transparent`}
                           text-gray-400 hover:text-white
                           cursor-pointer text-base 
                           `} >
            <div
          
                className={`px-3 py-1 my-auto flex items-center content-center
                    
                     `}>
            
              
                
                  <div className="flex-0">
                    {e?.name}
                  </div>
               
             
             </div>
          </a>
        </Link>
        )}




       

      </nav>
      
    </div> 

    <div className="mx-auto mt-5 px-4 overflow-hidden sm:px-6 lg:px-8 text-sm">
      <nav className=" flex flex-wrap justify-center " aria-label="Footer">


        {footerlist_sub.map((e)=> {

          return (<div 
                  key={e.name}
                  className="my-auto mx-2 flex items-center content-center "
                >

                

                <Link href={`${e.url}`}>
                  <a 
                    target={`${e.internal == '1' ? target : '_blank'}`}
                    rel={`${e.internal == '1' ? rel : 'noreferrer'}`}
                    className={
                    `
                    text-gray-400 hover:text-white
                                cursor-pointer text-base group
                                flex items-center content-center
                  `}>
                    
                      <div className="flex-0">
                        {e.name}
                      </div>

                  </a>
                </Link>
              </div>)
        }
          
        )}



      </nav>
      
    </div>

    <div className="mt-5 text-center justify-center mx-auto ">


      
        
        {/* COPYRIGHT */}
        <div 
            className="my-auto mx-2 "
          >
          <div className=" inline flex-1 text-center mx-auto  text-gray-400">
          <Link href={`/`}>
            <a 
              target={``}
              rel={``}
              className={
              `
               text-xs italic flex-0
                           text-gray-400 hover:text-white
                           cursor-pointer  group
            `}>
                  {`© ${new Date().getFullYear()}, `}
                  {process.env.NEXT_PUBLIC_PARENT_BRAND}
               
                  {`. All rights reserved.`}
            </a>
          </Link>
          </div>
        </div>


      

      
    </div>  

  </footer>
  </>
  )

  
}

export default Footer

